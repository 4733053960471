export default class Sell {
    constructor(
        title,
        description,
        price,
        quantity
    ) {
        this.title = title;
        this.description = description;
        this.price = price;
        this.quantity = quantity;
    }
}