<template>
    <b-modal id="modal-add-item" :title="modalTitle" hide-footer size="lg" centered>
        <div class="card-body">
            <form>
                <div class="row">
                    <div class="col-lg-12">
                        <div class="mb-3">
                            <multiselect
                                v-model="selectedService"
                                :options="services"
                                label="title"
                                placeholder="Choisir une prestation"
                                track-by="id"
                                @select="onSelectService"
                                select-label="Taper Entrée pour sélectionner"
                                deselect-label="Taper Entrée pour déselectionner"
                                selected-label="Sélectionné"
                            >
                                <span slot="noResult">Aucune prestation trouvée</span>
                                <span slot="noOptions">Aucune prestation trouvée</span>
                            </multiselect>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-6">
                        <div class="mb-3">
                            <label for="firstname">Titre</label>
                            <input
                                v-model="item.title"
                                type="text"
                                class="form-control"
                            />
                        </div>
                    </div>
                    <div class="col-md-6">
                        <div class="mb-3">
                            <label for="price">Prix</label>
                            <div class="input-group">
                                <currency-input 
                                    v-model="item.price"
                                ></currency-input>
                                <div class="input-group-append">
                                    <span class="input-group-text">€</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-lg-12">
                        <div class="mb-3">
                            <label for="title">Description de la prestation</label>
                            <textarea
                                v-model="item.description"
                                class="form-control"
                                placeholder="Entrer une description"
                                rows="3"
                            ></textarea>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-6">
                        <div class="mb-3">
                            <label>Quantité</label>
                            <div class="input-group">
                                <input
                                    v-model="item.quantity"
                                    v-mask="'#####'"
                                    type="text"
                                    class="form-control"
                                />
                                <div class="input-group-append" v-if="selectedService && selectedService.unit">
                                    <span class="input-group-text">{{ (selectedService.unit != '') ? (selectedService.unit == 'fixed' ? 'Forfait' : selectedService.unit) : '' }}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-lg-12">
                    <div class="text-end">
                        <button @click.prevent="addSellItem()" :disabled="processing" class="btn btn-primary">
                            Ajouter la prestation
                            <i class="uil uil-plus ms-2"></i>
                        </button>
                    </div>
                    </div>
                </div>
            </form>
        </div>
    </b-modal>
</template>

<script>
    import Swal from "sweetalert2";
    import api from '../api/api';
    import SellItem from '../models/sellitem';
    import Multiselect from "vue-multiselect";
    import Utilities from "../services/utilities";

    export default {
        name: 'AddSellItem',
        data() {
            return {
                errors: [],
                item: new SellItem('', '', '', 1),
                modalTitle: 'Ajouter une prestation à la vente',
                services: [],
                formattedPrice: '',
                selectedService: null,
                processing: false
            }
        },
        props: ['sell'],
        components: {
            Multiselect
        },
        mounted() {
          this.loadServices();
        },
        watch: {
            selectedService(data) {
                if (data) {
                    this.item.title = data.title;
                    this.item.description = data.description;
                    this.item.price = data.price;
                }
            }
        },
        methods: {
            loadServices() {
                api
                    .get('/services')
                    .then(
                        (response) => {
                            if (response && response.status == 200) {
                                if (Array.isArray(response.data)) {
                                    this.services = response.data;
                                }
                            }
                        },
                        () => {
                            Utilities.errormsg();
                        }
                    )
            },
            itemValid() {
                this.errors = [];
    
                if (!this.item.title) {
                    this.errors.push('Le titre est obligatoire');
                }
    
                if (!this.item.price) {
                    this.errors.push('Le prix est obligatoire');
                }
    
                if (!this.item.quantity) {
                    this.errors.push('La quantité est obligatoire');
                }
    
                if (this.errors.length == 0) {
                    return true;
                } else {
                    Swal.fire("Erreur", this.errors[0], "error");
                    return false;
                }
            },
            addSellItem: function () {
                if (this.itemValid()) {
                    if (this.processing === true) {
                        return;
                    }

                    this.processing = true;
                    this.item.service = '/api/services/' + this.selectedService.id;
                    this.item.sell = '/api/sales/' + this.sell.id;
                    this.item.quantity = parseFloat(this.item.quantity);

                    api
                        .post('/sell_items', this.item)
                        .then(
                            (response) => {
                                if (response && response.status == 201) {
                                    Utilities.successmsg("La prestation a bien été ajoutée !");
                                    this.$emit('items-updated');
                                    this.item = new SellItem('', '', '', 1);
                                    this.formattedPrice = '';
                                    this.selectedService = null;
                                    this.processing = false;
                                }
                            },
                            () => {
                                Utilities.errormsg();
                                this.processing = false;
                            }
                        )
                }
            },
            onSelectService(option) {
                this.selectedService = {...option};
            },
        }
    }
</script>

<style lang="scss">
    .input-group > div:first-child {
        width: calc(100% - 50px);
    }

    .input-group-append {
        width: 50px;
    }

    .input-group-text {
        justify-content: center;
    }
</style>